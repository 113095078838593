import React from "react"
import Layout from "../../components/layout"
import { Container, Wrapper } from "../../theme"
import {
  ServicesRow,
  Process,
  ServiceExamples,
  ContentGrid,
  CallToAction,
  IconRow,
  Seo,
} from "../../components"

const WebsiteDevelopment = () => {
  return (
    <Layout title="Website Design and Development" type="webdev">
      <Seo
        title="Websites that generate more leads and increase your revenue"
        description="Our team specializes in modern websites built with the latest technology and optimized for conversions! Hire the CheshTech team to develop a website that makes your company money and gives you the analytics' reports you need."
      />
      <Wrapper white>
        <Container>
          <IconRow type="website" />
        </Container>
        <ServicesRow
          serviceTitle="Website Design & Development"
          category="Our Development Services"
          service="webdev"
          subtitle="Did you know that your website is a direct reflection of your business?"
          paragraph1="Websites today are an art form as they need to be designed to not
          just look good, but also function as a 24/7 sales and marketing
          tool for your business. This is true for all types of websites
          including marketing websites, web applications, portfolios,
          e-commerce stores, and landing pages."
          paragraph2="The technology of websites continues to evolve with the
          introduction of the Headless CMS. A Headless CMS allows marketers,
          business owners, and non-technical people to use their favorite
          content management system (WordPress, Contentful, Shopify, etc.)
          while their website is built using the newest and latest
          technology."
          paragraph3="This allows the user to utilize WordPress for what it does best in
          managing content, while allowing the layout of their website to be
          built with technology such as Gatsby or Next.js. This results in
          next level performance that will make your business and website
          stand out from your competition."
        />
        <ContentGrid type="websites" />
        <CallToAction />
        <Process type="webdev" />
        <ServiceExamples service="website" title="Some of our work" />
      </Wrapper>
    </Layout>
  )
}

export default WebsiteDevelopment
